import React from 'react';
import {isEmpty} from "lodash";
import {Link} from "react-router-dom";
import "./PageRaces.css";

class PageRaces extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addingRace: false,
            nameNewRace: '',
        };
    }

    componentDidUpdate() {
        if (this.nameInput) this.nameInput.focus();
    }

    handleUpdateTextField = (e) => {
        this.setState({
            nameNewRace: e.target.value
        });
    };

    startEditMode = () => {
        this.setState({addingRace: true});
    };

    addRace = () => {
        const {nameNewRace: name} = this.state;
        const { addRace } = this.props;
        addRace(name);
        // Reset everything here
        this.resetPageState();
    };

    resetPageState = () => {
        this.setState({
            addingRace: false,
            nameNewRace: '',
        })
    };

    render() {
        const {addingRace} = this.state;
        const {races, selectRace} = this.props;

        return(
            <div className="page page-races">
                <h1>Races</h1>

                {isEmpty(races) && !addingRace && (
                    <span>Er zijn nog geen races</span>
                )}

                {!isEmpty(races) && races.map((race) => {
                    return (
                        <div className="race" key={race.id}>
                            <Link to={`/race/${race.id}/contestants`} onClick={() => selectRace(race.id, race.name)}>{race.name}</Link>
                        </div>
                    )
                })}

                {addingRace &&
                    <div className="race race-new">
                        <input placeholder="Type de naam van de wedstrijd"
                               type="text"
                               onChange={this.handleUpdateTextField.bind(this)}
                               ref={(input) => { this.nameInput = input; }}
                        />
                        <div className="RcButton RcSaveButton" onClick={() => this.addRace()}>Opslaan</div>
                        <div className="RcButton RcCancelButton" onClick={() => this.resetPageState()}>Annuleren</div>
                    </div>
                }

                {!addingRace &&
                    <div className="RcButton" onClick={() => this.startEditMode()}>+</div>
                }


            </div>
        );
    }
}

export default PageRaces;