import React from 'react';
import {HashRouter as Router, Route} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// Components
import PageRaces from "./pages/PageRacesContainer";
import PageContestants from "./pages/PageContestantsContainer";
import PageRanking from "./pages/PageRankingContainer";
import {store} from "./index";
import TemporaryDrawer from "./components/TemporaryDrawer";

const PageTimers = React.lazy(() => import('./pages/PageTimersContainer'));

// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//     },
//     menuButton: {
//         marginRight: theme.spacing(2),
//     },
//     title: {
//         flexGrow: 1,
//     },
// }));






export default function App() {

    return (
        <Router>
            <div className="App">
                <AppBar position="static">
                    <Toolbar>
                        <TemporaryDrawer/>

                    </Toolbar>
                </AppBar>


                {/*<nav>*/}
                {/*    <ul>*/}
                {/*        <li className="title">*/}
                {/*            <h1><Link to="/">Menu</Link> | <Link to="/state">state</Link>*/}
                {/*            </h1>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</nav>*/}



                <Route path="/" exact component={PageRaces} />

                <Route path="/race/:id/contestants" component={PageContestants} />

                <React.Suspense fallback={<div>Loading....</div>}>
                    <Route path="/race/:id/timers" exact component={PageTimers} />
                </React.Suspense>

                <Route path="/race/:id/ranking" component={PageRanking} />

                <Route path="/state" component={PageState} />

            </div>
        </Router>
    );
}



const PageState = () => (
    <div style={{margin: '70px 20px'}}>{JSON.stringify(store.getState())}</div>
);

