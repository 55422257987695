import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import {Provider} from "react-redux";
import './index.css';
import App from './App';
import {appStateReducer} from "./reducers/AppStateReducer";
import {ErrorBoundary} from "./ErrorBoundary";
import {SWRConfig} from "swr";
import * as serviceWorker from './serviceWorker';

const LOCAL_STORAGE_STATE_KEY = 'completeStateObj';
const stateFromStorage = localStorage && localStorage.getItem(LOCAL_STORAGE_STATE_KEY);

export let initialState = {
    races: [],
    contestants: [],
    timers: [],
};

if (stateFromStorage && typeof stateFromStorage !== 'undefined') {
    const stateObj = JSON.parse(stateFromStorage);
    console.log('Restoring state from storage', stateObj);
    initialState = stateObj;
}

export const store = createStore(
    appStateReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

store.subscribe(() => {
    if (localStorage) {
        localStorage.setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(store.getState()));
    }
});

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <SWRConfig value={{
                refreshInterval: 30000,
                fetcher: (...args) => fetch(...args).then(res => res.json())
            }}>
                <App />
            </SWRConfig>
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
