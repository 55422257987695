export const calculateNewNumberOfMillis = (start, end, previousMillis = 0) => {
    return end - start + previousMillis;
};

export const createStopwatchLabelObject = (durationInMillis) => {

    let durationHours = 0, durationMinutes = 0, durationSecs = 0, durationCents = 0;

    if (durationInMillis) {
        // We need 100th of seconds not thousands
        const durationCentillis = Math.round(durationInMillis / 10);
        durationHours = Math.floor(durationCentillis / 360000);
        durationMinutes = Math.floor((durationCentillis / 6000) % 60);
        durationSecs = Math.floor((durationCentillis / 100) % 60);
        durationCents = Math.round((durationCentillis % 100));
    }

    return {
        hours: zeroPad(durationHours),
        minutes: zeroPad(durationMinutes),
        seconds: zeroPad(durationSecs),
        centillis: zeroPad(durationCents),
    };
};

export const zeroPad = (number) => {
    return ('' + number).padStart(2, '0');
};