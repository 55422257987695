import React from 'react';
import {isEmpty} from "lodash";
import "./PageContestants.css";
import {createStopwatchLabelObject} from "../utils";
import {store} from "../index";
import cx from 'classnames';
import Button from '@material-ui/core/Button';

class PageContestants extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingContestant: null,
            editedStartNumber: null,
            editedName: '',
            sortOn: 'startnumber:asc',
        };
    }

    componentDidUpdate() {
        if (this.nameInput) this.nameInput.focus();
    }

    addContestant = () => {
        const {race, dispatch} = this.props;
        dispatch({
            type: 'RACE_ADD_CONTESTANT',
            payload: {
                id: new Date().getTime(), // guid, global unique id
                raceId: race.id,
            }
        });
    };

    startEditMode = (contestant) => {
        this.setState({
            editingContestant: contestant.id,
            editedName: contestant.name,
            editedParticipantCount: contestant.participantCount,
        });
    };

    // handleUpdateStartNumber = (e) => {
    //     this.setState({
    //         editedStartNumber: e.target.value
    //     });
    // };

    handleKeyPress = (e, nextContestant) => {
        if (e.keyCode === 13) {
            this.saveContestant();
            if (nextContestant) {
                this.setState({
                    editingContestant: nextContestant.id,
                    editedStartNumber: null,
                    editedName: nextContestant.name,
                    editedParticipantCount: nextContestant.participantCount,
                });
            }
        } else if (e.key === 'Escape') {
            this.resetPageState();
        } else if (e.key === 'Tab') {
            e.preventDefault();
            this.saveContestant();
            if (nextContestant) {
                this.setState({
                    editingContestant: nextContestant.id,
                    editedStartNumber: null,
                    editedName: nextContestant.name,
                    editedParticipantCount: nextContestant.participantCount,
                });
            }
        }
    };

    handleUpdateName = (e) => {
        this.setState({
            editedName: e.target.value
        });
    };

    handleUpdateParticipantCount = (e) => {
        this.setState({
            editedParticipantCount: e.target.value
        });
    };

    saveContestant = () => {
        const {dispatch} = this.props;
        const {editingContestant: id, editedName, editedParticipantCount } = this.state;
        dispatch({
            type: 'RACE_UPDATE_CONTESTANT',
            payload: {
                id,
                name: editedName,
                participantCount: editedParticipantCount,
            }
        });
        this.resetPageState();
    };

    sort = (column) => {
        const {sortOn} = this.state;
        const tokens = sortOn.split(':');

        let newSortOrder;
        if (column !== tokens[0]) {
            // sort on new column, start ascending
            newSortOrder = `${column}:asc`;
        } else {
            const sortSwap = tokens[1] === 'asc' ? 'desc' : 'asc';
            newSortOrder = `${column}:${sortSwap}`;
        }

        this.setState({
            sortOn: newSortOrder
        });
    };

    resetPageState = () => {
        this.setState({
            editingContestant: null,
            editedStartNumber: null,
            editedName: '',
        });
    };

    toggleActive = (contestant) => {
        const {id, active, start} = contestant;
        if (start > 0) { // this means the timer is running. We cannot toggle active/inactive
            return;
        }

        store.dispatch({
            type: 'SET_CONTESTANT_INACTIVE',
            payload: {
                id,
                active: !active,
            }
        });
    };

    onReset = (contestantId, start) => {
        if (start) return; // we do not allow resetting a running timer

        store.dispatch({
            type: 'TIMER_RESET',
            payload: {
                contestantId,
            }
        });
    };

    render() {
        const {contestants: contestantsUnsorted, race, timers} = this.props;
        const {editingContestant, editedName, editedParticipantCount, sortOn} = this.state;
        const sortOnTokens = sortOn.split(':');
        const sortCol = sortOnTokens[0];
        const order = sortOnTokens[1];

        let contestants;
        if (sortCol === 'name') {
            contestants = contestantsUnsorted.sort((a, b) => {
                if (order === 'asc') {
                    return ('' + a.name).localeCompare(b.name);
                }
                return ('' + b.name).localeCompare(a.name);
            })
        } if (sortCol === 'startnumber') {
            contestants = contestantsUnsorted.sort((a, b) => {
                if (order === 'asc') {
                    return a.startNumber < b.startNumber ? -1 : 1;
                }
                return a.startNumber > b.startNumber ? -1 : 1;

            })
        } else {
            contestants = contestantsUnsorted;
        }

        const startNumberCx = cx('sortIcon', 'fa', {
            'fa-angle-down': sortCol === 'startnumber' && order === 'asc',
            'fa-angle-up': sortCol === 'startnumber' && order === 'desc',
        });

        const nameCx = cx('sortIcon', 'fa', {
            'fa-angle-down': sortCol === 'name' && order === 'asc',
            'fa-angle-up': sortCol === 'name' && order === 'desc',
        });

        return(
            <div className="page page-contestants">

                <h2>
                    <span className="text">{race.name} ({contestants.length})</span>
                    {/*<RaceNavbar raceId={race.id}/>*/}

                    <Button variant="contained" color="primary" onClick={() => this.addContestant()}>
                        + Deelnemer
                    </Button>
                </h2>

                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th className="sortable" onClick={() => this.sort('startnumber')}>
                                Startnr. <span className={startNumberCx}/>
                            </th>
                            <th className="sortable" onClick={() => this.sort('name')}>
                                Naam <span className={nameCx}/>
                            </th>
                            <th>Aantal pers.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(contestants) && contestants.map((contestant, index, array) => {
                            const timer = timers.find(timer => timer.contestantId === contestant.id);
                            const timerLabels = createStopwatchLabelObject(timer.totalDurationExcCurrentRunMillis);
                            const rowInEditMode = editingContestant === contestant.id;

                            // const nextContestant = array.length > index + 1 ? array[index + 1] : null;

                            const classes = cx('contestant', {
                                'isNotActive': !contestant.active,
                            });

                            return (
                                <tr className={classes} key={contestant.id}>
                                    <td className="active">
                                        <input type="checkbox" checked={contestant.active} onChange={() => this.toggleActive(contestant)}/>
                                    </td>
                                    {sortOn === 'ranking' &&
                                        <div className="position number">{index + 1}</div>
                                    }
                                    <td className="startNumber" title={contestant.id}>
                                        <span>{contestant.startNumber}</span>
                                    </td>
                                    <td className="name">
                                        {!rowInEditMode && <span>{contestant.name}</span> }
                                        {rowInEditMode &&
                                            <input type="text" placeholder="Naam"
                                                   ref={(input) => { this.nameInput = input; }}
                                                   onChange={this.handleUpdateName}
                                                   // onKeyDown={(e) => this.handleKeyPress(e, nextContestant)}
                                                   value={editedName}/>
                                        }
                                    </td>
                                    <td className="participantCount">
                                        {!rowInEditMode && <span>{contestant.participantCount}</span> }
                                        {rowInEditMode &&
                                        <input type="text" placeholder="Aantal deelnemers"
                                               ref={(input) => { this.participantInput = input; }}
                                               onChange={this.handleUpdateParticipantCount}
                                               // onKeyDown={(e) => this.handleKeyPress(e, nextContestant)}
                                               value={editedParticipantCount}/>
                                        }
                                    </td>
                                    <td className="timerResult">
                                        {`${timerLabels.minutes}:${timerLabels.seconds}:${timerLabels.centillis}`}
                                    </td>
                                    <td className="buttonWrapper">
                                        {!rowInEditMode &&
                                            <div className="RcButton" onClick={() => contestant.active ? this.startEditMode(contestant) : ''} >Wijzig</div>
                                        }
                                        {rowInEditMode &&
                                            <>
                                                <div className="RcButton RcSaveButton" onClick={() => this.saveContestant()}>Opslaan</div>
                                                <div className="RcButton RcCancelButton" onClick={() => this.resetPageState()}>Annuleren</div>
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {!timer.start && !timer.lock &&
                                            <div style={{backgroundColor: 'darkred', color: 'white'}} className="RcButton"
                                                 onClick={() => contestant.active ? this.onReset(contestant.id, timer.start) : ''}>RESET!</div>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default PageContestants;