import {initialState} from "../index";
import {calculateNewNumberOfMillis} from "../utils";

export const appStateReducer = (state = initialState, action) => {

    if (action.type === 'ADD_RACE') {
        return Object.assign({}, state, {
            races: [
                ...state.races,
                {
                    id: state.races.length,
                    name: action.payload.name
                },
            ]
        });
    }
    if (action.type === 'RACE_ADD_CONTESTANT') {
        const raceId = action.payload.raceId;
        const numberOfContestantsInThisRace = state.contestants.reduce(
            (acc, current)=> current.raceId === raceId ? ++acc: acc, 0);

        // Contestant
        const contestantId = action.payload.id;
        const startNumber = numberOfContestantsInThisRace + 1;
        const newContestant = {
            id: contestantId,
            startNumber,
            raceId,
            active: true,
            name: '',
        };

        // Timer
        const newTimer = {
            contestantId,
            raceId,
            start: 0,
            totalDurationExcCurrentRunMillis: 0,
        };

        return Object.assign({}, state, {
            contestants: [...state.contestants, newContestant],
            timers: [...state.timers, newTimer],
        });
    }
    if (action.type === 'RACE_UPDATE_CONTESTANT') {
        const copyContestants = state.contestants.slice(0);
        const contestant = copyContestants.find(contestant => contestant.id === action.payload.id);
        contestant.name = action.payload.name;
        contestant.participantCount = action.payload.participantCount;
        return {
            ...state,
            contestants: copyContestants,
        }
    }
    if (action.type === 'SET_CONTESTANT_INACTIVE') {
        const copyContestants = state.contestants.slice(0);
        const contestant = copyContestants.find(contestant => contestant.id === action.payload.id);
        contestant.active = action.payload.active;
        return {
            ...state,
            contestants: copyContestants,
        }
    }
    if (action.type === 'TIMER_START') {
        const copyTimers = state.timers.slice(0);
        const timer = copyTimers.find(timer => timer.contestantId === action.payload.contestantId);
        timer.start = action.payload.start;
        return {
            ...state,
            timers: copyTimers,
        }
    }

    if (action.type === 'TIMER_STOP') {
        const copyTimers = state.timers.slice(0);
        const timer = copyTimers.find(timer => timer.contestantId === action.payload.contestantId);
        timer.totalDurationExcCurrentRunMillis = calculateNewNumberOfMillis(
            timer.start, action.payload.end, timer.totalDurationExcCurrentRunMillis);
        timer.start = 0;
        return {
            ...state,
            timers: copyTimers,
        }
    }

    if (action.type === 'TIMER_RESET') {
        const copyTimers = state.timers.slice(0);
        const timer = copyTimers.find(timer => timer.contestantId === action.payload.contestantId);
        timer.totalDurationExcCurrentRunMillis = 0;
        timer.start = 0;
        return {
            ...state,
            timers: copyTimers,
        }
    }

    if (action.type === 'TIMER_LOCK') {
        const copyTimers = state.timers.slice(0);
        const timer = copyTimers.find(timer => timer.contestantId === action.payload.contestantId);
        timer.lock = action.payload.lock;
        return {
            ...state,
            timers: copyTimers,
        }
    }

    if (action.type === 'SELECT_RACE') {
        return {
            ...state,
            selectedRace: {
                id: action.payload.id,
                name: action.payload.name,
            }
        }
    }

    return state;
};