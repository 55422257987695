import {connect} from "react-redux";
import PageRaces from "./PageRaces";


const mapStateToProps = state => ({
    races: state.races,
    nextRaceId: state.races.length,
});

const actionCreators = {
    selectRace: (id, name) => ({ type: 'SELECT_RACE', payload: { id, name }}),
    addRace: (name) => ({
        type: 'ADD_RACE',
        payload: {
            name,
        }
    })
};

export default connect(mapStateToProps, actionCreators)(PageRaces);