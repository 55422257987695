import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import AlarmIcon from '@material-ui/icons/Alarm';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


const useStyles = makeStyles({
    list: {
        width: 250,
    },
});

export default function TemporaryDrawer() {
    const classes = useStyles();
    const selectedRace = useSelector((state) => state.selectedRace);
    const [state, setState] = React.useState({
        open: false,
    });

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, open: open});
    };

    return (
        <div>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer( true)}>
                <MenuIcon />
            </IconButton>

            <Drawer anchor="left" open={state['open']} onClose={toggleDrawer(false)}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <Link to={'/'}>
                            <ListItem button key={'Races'}>
                                <ListItemIcon>
                                    <AssistantPhotoIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'Races'} />
                            </ListItem>
                        </Link>

                        {!!selectedRace &&

                            <>
                                <Divider />

                                <ListItem>
                                    <ListItemText primary={selectedRace.name} />
                                </ListItem>

                                <Divider />

                                <Link to={`/race/${selectedRace.id}/contestants`}>
                                    <ListItem button key={'Contestants'}>
                                        <ListItemIcon>
                                            <SupervisorAccountIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={'Contestants'} />
                                    </ListItem>
                                </Link>

                                <Link to={`/race/${selectedRace.id}/timers`}>
                                    <ListItem button key={'Timers'}>
                                        <ListItemIcon>
                                            <AlarmIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={'Timers'} />
                                    </ListItem>
                                </Link>

                                <Link to={`/race/${selectedRace.id}/ranking`}>
                                    <ListItem button key={'Ranking'}>
                                        <ListItemIcon>
                                            <FormatListNumberedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={'Ranking'} />
                                    </ListItem>
                                </Link>

                            </>
                        }

                    </List>

                </div>
            </Drawer>
        </div>
    );
}