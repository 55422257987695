import React from 'react';
import {isEmpty} from "lodash";
import "./PageContestants.css";
import {createStopwatchLabelObject} from "../utils";
import "./PageRanking.css";

class PageRanking extends React.Component {

    render() {
        const {contestants: contestantsUnfiltered, timers} = this.props;

        const contestants = contestantsUnfiltered.filter(contestant => contestant.active);

        contestants.sort((a, b) => {
            // TODO contestants do not have timers! Find timer via contestant number
            const timerA = timers.find(timer => timer.contestantId === a.id);
            const timerB = timers.find(timer => timer.contestantId === b.id);
            return timerA.totalDurationExcCurrentRunMillis < timerB.totalDurationExcCurrentRunMillis ? -1 : 1;
        });

        return(
            <div className="page page-contestants page-ranking">

                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th className="headStartNumber">Startnr.</th>
                        <th>Naam</th>
                        <th>Aantal pers.</th>
                        <th>Tijd</th>
                    </tr>
                    </thead>
                    <tbody>

                        {!isEmpty(contestants) && contestants.map((contestant, index) => {
                            const timer = timers.find(timer => timer.contestantId === contestant.id);
                            const timerLabels = createStopwatchLabelObject(timer.totalDurationExcCurrentRunMillis);

                            return (
                                <tr className="contestant" key={contestant.startNumber}>
                                    <td className="position number">{index + 1}</td>
                                    <td className="startNumber">
                                        <span>{contestant.startNumber}</span>
                                    </td>
                                    <td className="name"><span>{contestant.name}</span></td>
                                    <td>?</td>
                                    <td className="timerResult">
                                        <span className="timerPart minutes">{timerLabels.minutes}</span>:
                                        <span className="timerPart seconds">{timerLabels.seconds}</span>
                                        <span className="timerPart centillis">{timerLabels.centillis}</span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default PageRanking;