import {connect} from "react-redux";
import PageRanking from "./PageRanking";
import {withRouter} from "react-router-dom";


const mapStateToProps = (state, ownProps) => ({
    race: state.races.find(race => race.id === Number.parseInt(ownProps.match.params.id)),
    contestants: state.contestants.filter(contestant=>contestant.raceId === Number.parseInt(ownProps.match.params.id)),
    timers: state.timers.filter(timer=>timer.raceId === Number.parseInt(ownProps.match.params.id)),
});



export default withRouter(
    connect(mapStateToProps)(PageRanking));